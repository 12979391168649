import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import logoClearSight from "../../assets/icon/Logo-02.svg";
import icon1 from "../../assets/icon/ico-19.svg";
import icon2 from "../../assets/icon/ico-20.svg";
import icon3 from "../../assets/icon/ico-22.svg";
import icon4 from "../../assets/icon/ico-23.svg";
import closeButton from "../../assets/icon/closeButton.svg";

import "./NavBar.scss";

const navigationLinks = [
  { name: "Home", href: "/home" },
  { name: "How it works", href: "/how-it-works" },
  { name: "Wallet", href: "/wallet" },
  { name: "Debit Card", href: "/debit-card" },
  { name: "About Us", href: "/about-us" },
];

export const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [openToolTipLogin, setOpenToolTipLogin] = useState(false);
  const [openToolTipSignUP, setOpenToolTipSignUp] = useState(false);

  const handleClose = (props) => {
    if (props === "logIn") {
      setOpenToolTipLogin(false);
    } else {
      setOpenToolTipSignUp(false);
    }
  };

  const handleOpen = (props) => {
    if (props === "logIn") {
      setOpenToolTipLogin(true);
    } else {
      setOpenToolTipSignUp(true);
    }
  };

  return (
    <>
      <Container>
        <Hidden mdDown>
          <Grid container className="conatiner-navbar">
            <Grid item md={2} sm={2} className="logo-container">
              <a href="/home">
                <img src={logoClearSight} alt={"clearsight"} className="logo-icon" />
              </a>
            </Grid>
            <Grid item md={7} sm={7} className="nav-bar-hamb">
              <Grid className="menu-item-container">
                {navigationLinks.map((item) => (
                  <Link
                    to={item.href}
                    key={item.name}
                    className={
                      window.location.pathname === `${item.href}`
                        ? "menu-item-active"
                        : "menu-item"
                    }
                  >
                    {item.name}
                  </Link>
                ))}
              </Grid>
            </Grid>
            <Grid item md={3} sm={3} className="login-container" container>
              <Tooltip
                open={openToolTipLogin}
                onClose={() => handleClose("logIn")}
                onOpen={() => handleOpen("logIn")}
                title="Comming soon"
              >
                <Button variant="text" className="btn-login">
                  Swap
                </Button>
              </Tooltip>
              <Tooltip
                open={openToolTipSignUP}
                onClose={() => handleClose("signUp")}
                onOpen={() => handleOpen("signUp")}
                title="Comming soon"
              >
                <Button variant="outlined" className="btn-sign-up">
                  Enter App
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid className="conatiner-navbar-mobile">
            <Grid className="nav-bar-hamb">
              <Grid container className="hamb-menu-mobile">
                <img src={logoClearSight} alt={"clearsight"} className="hamb-logo-menu" />
                <IconButton onClick={() => setOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        className="swiper-drawer"
      >
        <Grid className="hamb-menu-content">
          <Grid container className="header-hamb-menu">
            <img src={logoClearSight} alt={"clearsight"} className="hamb-logo-menu" />
            <img
              onClick={() => setOpen(false)}
              src={closeButton}
              className="hamb-close-icon"
              alt={"close-button"}
            />
          </Grid>
          <Grid className="navigation-hamb-menu">
            <List
              onClick={() => setOpen(false)}
              className="navigation-hamb-menu"
            >
              {navigationLinks.map((item) => (
                <ListItem key={item.name}>
                  <Link
                    to={item.href}
                    className={
                      window.location.pathname === `${item.href}`
                        ? "menu-item-active"
                        : "menu-item"
                    }
                  >
                    {item.name}
                  </Link>
                </ListItem>
              ))}
            </List>
            <Grid className="btn-conatiner-hamb">
              <Button variant="text" className="btn-login">
                Swap
              </Button>

              <Button variant="outlined" className="btn-sign-up">
                Enter App
              </Button>
            </Grid>
          </Grid>
          <Grid container className="sm-container">
            <img src={icon1} alt="social-media" className="social-media-icon" />
            <img src={icon2} alt="social-media" className="social-media-icon" />
            <img src={icon3} alt="social-media" className="social-media-icon" />
            <img src={icon4} alt="social-media" className="social-media-icon" />
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
};
