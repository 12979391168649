import { Grid, Typography } from "@material-ui/core";

import "./DebitCardComponent.scss";

type DebitCardComponentProps = {
  icon: string;
  title: string;
  description?: string;
};

export const DebitCardComponent: React.FC<DebitCardComponentProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <Grid className="debit-card-component">
      <img src={icon} />
      <Typography className="debit-card-title">{title}</Typography>
      <Typography className="debit-card-description">{description}</Typography>
    </Grid>
  );
};
