import { useForm } from "react-hook-form";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";
import { Grid, Typography } from "@material-ui/core";
import newslatterImg from "assets/images/img-08.png";
import LightSpeed from "react-reveal/LightSpeed";

import "./Newslatter.scss";

type NewslatterProps = {
  isBasicBg: string;
};

export const Newslatter: React.FC<NewslatterProps> = ({ isBasicBg }) => {
  const urlMail =
    "https://finance.us6.list-manage.com/subscribe/post?u=98b41a295edb23c0f4a7609f9&amp;id=1a704a895e";

  const newslatterBackgound = [
    !isBasicBg
      ? " contianer-newslatter-component"
      : "contianer-newslatter-component-basic",
  ];
  const {} = useForm();
  return (
    <Grid className={newslatterBackgound.join(" ")}>
      <Grid md={12} className="newslatter-content" container>
        <Grid item md={6} sm={6} className="left-side-newslatter">
          <img src={newslatterImg} className="img-newslatter" />
        </Grid>
        <Grid item md={6} sm={6} className="right-side-newslatter">
          <LightSpeed top cascade>
            <Typography className="clearsight-title">Clearsight</Typography>
          </LightSpeed>
          <Typography className="main-title-newslater">
            Let’s Stay in Touch
          </Typography>
          <Typography className="description-newslater">
            Please input your email address below to stay in touch with us. We
            promise not to overwhelm you with mails, we will only contact you if
            we believe that we have important information for you.
          </Typography>
          <Grid>
            <Typography className="get-started-title">
              Get Started Now!
            </Typography>

            <MailchimpSubscribe
              url={urlMail}
              render={({ subscribe, status, message }) => (
                <div>
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                  />
                </div>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
