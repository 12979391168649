import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

import './font.scss';

export const AppTheme = createTheme({
  typography: {
    fontFamily: "'Satoshi', sans-serif",
    h1: {
      fontSize: '96px',
      lineHeight: '100px',
      fontWeight: '700',
    },
    h2: {
      fontSize: '56px',
      lineHeight: '60px',
      fontWeight: '700',
    },
    h3: {
      fontSize: '36px',
      lineHeight: '38px',
      fontWeight: '500',
    },
    h4: {
      fontSize: '30px',
      lineHeight: '32px',
      fontWeight: '700',
    }
  },
  palette: {
    primary: {
      main: red[500],
    },
  },
});
