import { Grid, Typography } from "@material-ui/core";

import "./AboutUsCard.scss";

type AboutUsCardProps = {
  title?: string;
  dataForDescription?: any;
  backgroundColor?: string;
};

export const AboutUsCard: React.FC<AboutUsCardProps> = ({
  title,
  dataForDescription,
  backgroundColor,
}) => {

  const aboutUsCardClass = [
    backgroundColor === "freelancer" ? "about-us-card-freelancer" : "about-us-card-client"
  ]
  return (
    <Grid
      className={aboutUsCardClass.join(" ")}
    >
      <Grid className="card-content">
        <Typography className="main-title">Solutions to</Typography>
        <Typography className="second-title">{title}</Typography>
        {dataForDescription.map((item, key) => (
          <Typography className="description" id={key}>
            {item.label}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};
