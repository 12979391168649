import { ExplainerCard } from "components";
import { Grid } from "@material-ui/core";

import "./DifferentIconsComp.scss";

type DifferentIconsCompProps = {
  data?: any;
};

export const DifferentIconsComp: React.FC<DifferentIconsCompProps> = ({
  data,
}) => {
  return (
    <Grid container className="explainer-content" md={12}>
      {data.map((item, index) => (
        <ExplainerCard
          img={item.img}
          title={item.title}
          description={item.description}
          key={index}
        />
      ))}
    </Grid>
  );
};
