import {
  HomePage,
  WalletPage,
  DebitCardPage,
  AboutUsPage,
  NotFound,
  HowItWorksPage,
} from "pages";

export const PublicRoutes = [
  {
    component: HomePage,
    path: "home",
  },
  {
    component:  HowItWorksPage,
    path: "how-it-works",
  },
  {
    component:  WalletPage,
    path: "wallet",
  },
  {
    component:  DebitCardPage,
    path: "debit-card",
  },
  {
    component:  AboutUsPage,
    path: "about-us",
  },
  {
    component: NotFound,
    path: "*",
  },
];
