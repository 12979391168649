import { Button, Grid } from "@material-ui/core";

import "./ImageBackground.scss";

type ImageBackgroundProps = {
  title?: string;
  description?: string;
  buttonTitle?: string;
};

export const ImageBackground: React.FC<ImageBackgroundProps> = ({
  title,
  description,
  buttonTitle,
}) => {
  return (
    <Grid>
    <Grid className="img-bg-conatiner">
      <Grid className="img-bg-content">
        <h1 className="title-image-bg">{title}</h1>
        <p>{description}</p>
        <Button className="btn-component">{buttonTitle}</Button>
      </Grid>
    </Grid>
    </Grid>
  );
};
