import { Grid, Typography } from "@material-ui/core";

import "./FlexIconComponent.scss";

type FlexIconComponentProps = {
  data?: any;
};

export const FlexIconComponent: React.FC<FlexIconComponentProps> = ({
  data,
}) => {
  return (
    <>
      {data.length === 6 ? (
        <Grid className="flex-icon-component" md={12} sm={12} container>
          <Grid md={6} sm={6} item>
            {data.slice(0, 3).map((item, index) => (
              <Grid container className="icon-item-conatiner" key={index}>
                <img src={item.icon} />
                <Typography className="icon-title">{item.title}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid md={6} sm={6} item>
            {data.slice(3, 6).map((item,index) => (
              <Grid container className="icon-item-conatiner" key={index}>
                <img src={item.icon} />
                <Typography className="icon-title">{item.title}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid className="flex-icon-component" md={12} sm={12} container>
          <Grid md={6} sm={6} item>
            {data.slice(0, 2).map((item, index) => (
              <Grid container className="icon-item-conatiner" key={index}>
                <img src={item.icon} />
                <Typography className="icon-title">{item.title}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid md={6} sm={6} item>
            {data.slice(2, 4).map((item,index) => (
              <Grid container className="icon-item-conatiner" key={index}>
                <img src={item.icon} />
                <Typography className="icon-title">{item.title}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};
