import React, { useState } from "react";
import { Arrow } from "../Arrow/Arrow";
import { Grid } from "@material-ui/core";

import "./CollapseItem.scss";

type CollapseItemType = {
  title?: string;
  description?: string;
};

export const CollapseItem: React.FC<CollapseItemType> = ({
  title,
  description,
}) => {
  const [setActive, setActiveState] = useState("");

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "");
  };

  return (
    <Grid className="container-collapse">
      <h1 className="collapse-title" onClick={toggleAccordion}>
        {title}
        <Arrow direction={setActive === "active" ? "up" : "down"} />
      </h1>
      <p className={`collapse-description accordion ${setActive}`}>
        {description}
      </p>
    </Grid>
  );
};
