import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router-dom";
import { RouteSwitcher } from "components/RouterSwitcher/RouteSwitcher";
import { ThemeProvider } from "@material-ui/styles";
import { AppTheme } from "./theme/theme";
import { hotjar } from 'react-hotjar';

import "./i18n";
import "./index.scss";
import { GlobalLayout } from "layouts/GlobalLayout/GlobalLayout";

const history = createBrowserHistory();

ReactDOM.render(
  
  <ThemeProvider theme={AppTheme}>
    <Router history={history}>
      <Switch>
        <GlobalLayout path="/"/>
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);

hotjar.initialize(3167265, 6);