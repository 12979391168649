import { Grid, Typography } from "@material-ui/core";

import "./ExplainerCard.scss";

type ExplainerCardProps = {
  img?: string;
  title?: string;
  description?: string;
};

export const ExplainerCard: React.FC<ExplainerCardProps> = ({
  img,
  title,
  description,
}) => {
  return (
    <Grid className="conatiner-card" >
      <Grid className="icon-explanier-card-conatiner">
      <img src={img} className="explanier-card-icon" />
      </Grid>
      <Typography className="explanier-card-title">{title}</Typography>
      <Typography className="explanier-card-description">{description}</Typography>
    </Grid>
  );
};
