import * as React from "react";
import { useEffect, useState } from "react";
import { tabsList } from "services/config/tabsData/tabsList";
import { tabPanel } from "services/config/tabsData/tabsPanel";
import Reveal from "react-reveal/Fade";

import { Grid, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import iconTalnets from "../../assets/icon/img-100.png";
import arrowIcon from "../../assets/icon/img-110.png";

import "./ReactTabs.scss";

export const ReactTabs = () => {
  const [value, setValue] = useState("1");

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 480;

  const [itemResponsive, setItemResponsive] = useState(isMobile ? 4 : 9);

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="react-tabs-conatiner"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabsList.map((item, index) => (
              <Tab
                label={item.label}
                value={item.value}
                className="tab-item"
                key={index}
              />
            ))}
          </TabList>
        </Box>
        {tabPanel.map((item, index) => (
          <TabPanel value={item.value} key={index}>
            <Reveal effect="fadeInBottom" >
            <Grid container className="tab-container">
              {item.dataForTabs.slice(0, itemResponsive).map((item, index) => (
                    <Grid className="card-tab-container" key={index}>
                      <Typography>{item.title}</Typography>
                      <Grid className="icon-container">
                        <img src={iconTalnets} width="145px" />
                        <img src={arrowIcon} width="25px" />
                      </Grid>
                    </Grid>
              ))}
            </Grid>
            </Reveal>
            {itemResponsive === 4 ? (
              <Typography
                onClick={() => setItemResponsive(9)}
                className="load-more-btn"
              >
                Load More
              </Typography>
            ) : (
              <Typography
                onClick={() => setItemResponsive(4)}
                className="load-more-btn"
              >
                Show Less
              </Typography>
            )}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
