import { Grid, Link, Typography } from "@material-ui/core";
import logoClearSight from "../../assets/icon/Logo-02.svg";
import icon1 from "../../assets/icon/ico-19.svg";
import icon2 from "../../assets/icon/ico-20.svg";
import icon3 from "../../assets/icon/ico-22.svg";
import icon4 from "../../assets/icon/ico-23.svg";
import icon5 from "../../assets/icon/ico-21.svg";

import "./Footer.scss";

export const Footer = () => {
  return (
    <section className="conatiner-footer">
      <Grid container md={12} className="footer-section">
        <Grid item md={6} sm={6} className="left-side-footer">
          <img
            src={logoClearSight}
            alt="clearsight"
            className="clearsight-logo"
          />
          <Typography className="title-footer">
            ClearSight is a global freelance platform that offers reliable freelance solutions using decentralized technology such as blockchain and smart contracts.
          </Typography>
          <Grid container className="social-media-conatiner" id="social-media">
            <a href="https://t.me/clearsightfinance" target="_blank">
              <img src={icon1} className="social-media-icon" />
            </a>
            <a href="https://twitter.com/ClearSightFi" target="_blank">
              <img src={icon2} className="social-media-icon" />
            </a>
            <a href="https://discord.com/invite/clearsight" target="_blank">
              <img src={icon3} className="social-media-icon" />
            </a>
            <a
              href="https://www.instagram.com/clearsight.finance/"
              target="_blank"
            >
              <img src={icon4} className="social-media-icon" />
            </a>
            <a href="https://clearsightfinance.medium.com/" target="_blank">
              <img src={icon5} className="social-media-icon" />
            </a>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} className="right-side-footer">
          <Grid container className="page-links">
            <Grid className="links-conatiner">
              <a href="/home">Home</a>
              <a href="/how-it-works">How it works</a>
              <a href="/wallet">Wallet</a>
              <a href="/debit-card">Debit Card</a>
              <a href="/about-us">About Us</a>
            </Grid>
            <Grid className="terms-privacy-mobile">
              <a
                href=" https://app.termly.io/document/terms-of-use-for-website/4a4a40c1-24bc-4187-b6cd-840a3d4dc166"
                className="terms-mobile"
                target="_blank"
              >
                Terms of Service
              </a>
              <a className="terms-mobile" href=" https://app.termly.io/document/privacy-policy/134462af-d14f-4592-8d1e-34f701395933">Privacy Policy</a>
              <a
                className="terms-mobile"
                target="_blank"
                href="https://clearsight452-my.sharepoint.com/:b:/g/personal/aleksandar_janev_clearsight_finance/EV9Us0_SJmlFvIdWJAuyURUBOhyR1Qk10E3nbX6P0RAOgg?e=tlslbq"
              >
                Whitepaper
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
