import React from "react";
import { Grid } from "@material-ui/core";
import { Loading, NavBar } from "components";
import { Footer } from "../../section/Footer/Footer";
import { Redirect, Route, Switch } from "react-router-dom";
import { PublicRoutes } from "routes/PublicRoutes";
import { Helmet } from "react-helmet";

import "./GlobalLayout.scss";

export const GlobalLayout = ({ path }) => {
  const routes = PublicRoutes.map((route, index) => {
    const routeProps = { ...route, path: path + route.path };
    return <Route exact {...routeProps} key={route.path + index} />;
  });

  return (
    <Grid className="global-layout">
      <Helmet>
        <script
          type="text/javascript"
          src="https://app.termly.io/embed.min.js"
          data-auto-block="on"
          data-website-uuid="5f38d1f7-f2e6-4caa-aff5-c7bce2c3dc33"
        ></script>
      </Helmet>
      <NavBar />
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Redirect exact from="/" to="/home" />
          {routes}
        </Switch>
      </React.Suspense>
      <Footer />
    </Grid>
  );
};
