import { Button, Grid } from "@material-ui/core";

import "./Newslatter.scss";

const CustomForm = ({ status, message, onValidated }) => {
  let email;

  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <>
      <Grid className="input-global" container>
        <Grid md={8} xs={8} lg={8}>
          <input
            ref={(node) => (email = node)}
            type="email"
            placeholder="Enter your Email"
            className="input-newslatter"
          />
        </Grid>
        <Grid md={4} xs={4} lg={4} className="btn-conaitner">
          <Button className="btn-newslatter" onClick={submit}>
            {"submit"}
          </Button>
        </Grid>
      </Grid>
      <div className="message-handling">
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </>
  );
};

export default CustomForm;
