import { ExplainerCardFlex } from "components";
import { Grid, Typography } from "@material-ui/core";


import "./FullWidthImage.scss";

type FullWidthImageProps = {
  data?: any;
  img?: string;
  isDebitCard: boolean;
};

export const FullWidthImage: React.FC<FullWidthImageProps> = ({
  data,
  img,
  isDebitCard,
}) => {
  return (
    <Grid className="container-full-width" container>
      <Grid item md={6} sm={6} className="left-side">
        {!isDebitCard ? (
          <Grid>
            <Typography className="main-title-comp">FOR CLIENT</Typography>
            <Typography className="second-title-comp">
            Make Sure You Get the Job Done and Pay in Crypto or Fiat
            </Typography>
            <Typography className="description-comp">
            Browse our platform for the best talents fitting your needs.
            </Typography>
          </Grid>
        ) : (
          <Grid>
            <Typography className="main-title-comp">
              ClearSigh Debit Pro
            </Typography>
            <Typography className="second-title-comp">
              More Spending.{" "}
            </Typography>
            <Typography className="third-title-comp">More Rewards.</Typography>
            <Typography className="description-comp">
              With our Pro Card you earn more rewards while you spend
            </Typography>
          </Grid>
        )}

        <Grid>
          {data.map((item, index) => (
            <ExplainerCardFlex
              img={item.img}
              title={item.title}
              description={item.description}
              key={index}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item md={6} sm={6} className="right-side">
        <img src={img} alt="" />
      </Grid>
    </Grid>
  );
};
