export let tabPanel = [
  {
    value: "1",
    dataForTabs: [
      { title: "Web Engeineering" },
      { title: "Software Engineering" },
      { title: "Mobile Development" },
      { title: "Quality Assurance" },
      { title: "Blockchain development" },
      { title: "Cyber Security" },
      { title: "Devops & Sysops" },
      { title: "Databse engineering" },
    ],
  },
  {
    value: "2",
    dataForTabs: [
      { title: "Web Design" },
      { title: "Graphic Design" },
      { title: "Brand Design" },
      { title: "Art & Illustration" },
      { title: "Packaging and labels" },
      { title: "Visual & Print Design" },
      { title: "Fashion Design" },
      { title: "Architecture Design" },
    ],
  },
  {
    value: "3",
    dataForTabs: [
      { title: "Social Media" },
      { title: "SEO" },
      { title: "Public Relations" },
      { title: "Real Estate marketing" },
      { title: "Sales planning" },
      { title: "Crowdfunding" },
      { title: "Content & Video Marketing" },
      { title: "Physical marketing(bilboards)" },
    ],
  },
  {
    value: "4",
    dataForTabs: [
      { title: "Articles and blog posts" },
      { title: "Proofreading" },
      { title: "Editing" },
      { title: "Multilingual Translations" },
      { title: "Book Writing" },
      { title: "Presentation Writing" },
      { title: "Press Releases" },
      { title: "Script Writing" },
    ],
  },
  {
    value: "5",
    dataForTabs: [
      { title: "Virtual Assistants" },
      { title: "Live Chat Assistants" },
      { title: "Ticket Support Assistants" },
      { title: "Support Consulting" },
    ],
  },
  {
    value: "6",
    dataForTabs: [
      { title: "Financial Consulting" },
      { title: "Money Management" },
      { title: "Business Consulting" },
      { title: "Account Managment" },
      { title: "Blockchain Consulting" },
      { title: "Cryptocurrency Managment" },
      { title: "Business Relations" },
      { title: "Project & Product Management" },
    ],
  },
];
