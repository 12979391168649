import { Grid, Typography } from "@material-ui/core"
import Slide from 'react-reveal/Fade';
import "./ExplainerCardFlex.scss"

type ExplainerCardFlexProps = {
    img?: string,
    title?: string,
    description?: string
}

export const ExplainerCardFlex:React.FC<ExplainerCardFlexProps> = ({img,title,description}) => {
    return (
        <Grid container className="explainer-flex-conatiner">
            <Grid md={1} className="icon-conatiner">
            <Slide left>
            <img src={img} className="icon-explainer"/>
            </Slide>
            </Grid>
          
            <Grid className="title-container" md={10}>
                <Slide top cascade>
                <Typography className="main-title-explanier">{title}</Typography>
                </Slide>
                <Slide top cascade>
                <Typography className="description">{description}</Typography>
                </Slide>
            </Grid>
        </Grid>
    )
}